import React from 'react';
import classnames from 'classnames';
import { ConcreteTabProps, TabPanelChild, TabPanelProps, TabProps } from './types';

export const Tab = (props: TabProps) => null;

function ConcreteTab({ title, className, onClick }: ConcreteTabProps) {
  return (
    <li className={className} onClick={onClick}>
      <a>
        <span>{title}</span>
      </a>
    </li>
  );
}

function renderChild(render: boolean, child?: React.ReactChild, props?: object) {
  if (!child) return null;

  if (typeof child === 'string') {
    return render ? child : null;
  } else {
    const childElement = child as React.ReactElement;
    const className = classnames(childElement.props.className, !render ? 'is-hidden' : undefined);
    return React.cloneElement(childElement, {
      ...childElement.props,
      className,
      ...props,
    });
  }
}

function TabPanel({ className, children, activeTab: initialTab }: TabPanelProps) {
  const [activeTab, setActiveTab] = React.useState(initialTab || 0);

  const concreteTabs = [];
  const tabsContent = [];

  React.Children.forEach(children, ({ props }: TabPanelChild, index) => {
    const isActive = activeTab === index;
    const tabClassName = classnames({ 'is-active': isActive });
    concreteTabs.push(
      <ConcreteTab
        key={index.toString()}
        title={props.title}
        index={index}
        className={tabClassName}
        onClick={() => {
          setActiveTab(index);
        }}
      />
    );

    const childToRender = renderChild(isActive, props.children, { key: index.toString() });
    if (childToRender) tabsContent.push(childToRender);
  });

  const tabs = (
    <div className="tabs is-centered is-boxed is-large">
      <ul>{concreteTabs}</ul>
    </div>
  );

  return (
    <div className={className}>
      {tabs}
      <div className="tab-panel__tabs-content">{tabsContent}</div>
    </div>
  );
}

export default React.memo(TabPanel);
