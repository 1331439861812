import React from 'react';
import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';

import { REPO_ADDRESS } from '../../data/links';
import data from '../../data/mojaloopProject';

import Layout from '../../components/Layout';
import PaymentManagerLogo from '../../components/PaymentManagerLogo';
import TabPanel from '../../components/TabPanel';
import * as Assets from '../../assets';

const [tabOne, tabTwo] = data.tabPanel.tabs;

type CardItemProps = {
  children: string;
  title: string;
  icon?: React.ReactElement
};

function CardItem({ children, title, icon }: CardItemProps) {
  return (
    <div className="column is-centered card-item">
      <figure className="image is-spaced-bottom is-center">
        {icon}
      </figure>
      <span className="title is-size-6-desktop is-size-5-mobile has-text-weight-semibold">
        {title}
      </span>
      <p className="is-size-7-desktop is-size-6-mobile content-text">{children}</p>
    </div>
  );
}

type CardItemConfig = {
  title: string;
  text: string;
};


function getCardItemRender(tab: number) {
  const selectedIconSet: JSX.Element[] = [[
    <Assets.DataBaseLogo />, <Assets.LockShield />, <Assets.BusinessView />,
  ], [
    <Assets.TargetPath />, <Assets.Operations />, <Assets.Users />,
  ]][tab];
  return function cardItemRender(itemCfg: CardItemConfig, index: number) {
    return (
      <CardItem title={itemCfg.title} key={index.toString()} icon={selectedIconSet[index]}>
        {itemCfg.text}
      </CardItem>
    );
  }
}

type CategoryComponentProps = {
  title: string;
  children: React.ReactElement | React.ReactElement[];
};

function Category({ title, children }: CategoryComponentProps) {
  return (
    <div className="category">
      <div className="category__title">
        <span className="subtitle">{`${title}: `}</span>
      </div>
      <ul>
        <div className="tile is-ancestor">
          {React.Children.map(children, (item, index) => (
            <li key={index.toString()}>
              <div className="tile is-parent">
                <article className="tile is-child">
                  {item}
                </article>
              </div>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
}

// markup
export default function IndexPage() {
  return (
    <Layout>
      <section className="hero is-light">
        <div className="hero-body showcase-container">
          <div className="container has-text-centered">
            <figure className="image is-spaced-bottom">
              <PaymentManagerLogo className="logo-image" width={98} height={98} />
            </figure>
            <div className="title-section">
              <span className="title is-size-2-desktop is-size-3-mobile has-text-weight-semibold is-spaced">
                {data.title}
              </span>
              <span className="subtitle is-size-4-desktop is-size-5-mobile mw-500 main-subtitle">
                {data.subtitle}
              </span>
            </div>
            <div className="button-group">
              <div className="columns is-desktop">
                {data.buttons.map(({ className, label, href }, index) => (
                  <div className="column" key={index.toString()}>
                    <a className={className} href={href}>
                      {label}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="content-wrapper container is-desktop">
        <section>
          <div className="container">
            <div className="columns is-centered m-b-60 tab-panel__wrapper">
              <TabPanel className="column is-full tab-panel">
                <TabPanel.Tab title={tabOne.title}>
                  <div className="tab-item">
                    <div className="columns is-centered tab-item__row-one">
                      <div className="column center">
                        <figure className="image is-spaced-bottom">
                          <Assets.LaunchIcon />
                        </figure>
                      </div>
                      <div className="column is-two-thirds">
                        <span className="title is-size-3-desktop is-size-5-mobile">
                          {tabOne.rowOne?.title}
                        </span>
                        <p className="is-size-6-desktop is-size-6-mobile content-text is-spaced-top">
                          {tabOne.rowOne?.text}
                        </p>
                      </div>
                    </div>
                    <div className="columns is-centered tab-item__row-two">
                      {(tabOne.rowTwo?.cardItems || []).map(getCardItemRender(0)).slice(0, 3)}
                    </div>
                  </div>
                </TabPanel.Tab>
                <TabPanel.Tab title={tabTwo.title}>
                  <div className="tab-item">
                    <div className="columns is-centered tab-item__row-one">
                      <div className="column center">
                        <figure className="image is-spaced-bottom">
                          <Assets.Mountain />
                        </figure>
                      </div>
                      <div className="column is-two-thirds">
                        <span className="title is-size-3-desktop is-size-5-mobile">
                          {tabTwo.rowOne?.title}
                        </span>
                        <p className="is-size-6-desktop is-size-6-mobile content-text is-spaced-top">
                          {tabTwo.rowOne?.text}
                        </p>
                      </div>
                    </div>
                    <div className="columns is-centered tab-item__row-two">
                      {(tabTwo.rowTwo?.cardItems || []).map(getCardItemRender(1)).slice(0, 3)}
                    </div>
                  </div>
                </TabPanel.Tab>
              </TabPanel>
            </div>
          </div>
        </section>
        <section>
          <div className="container is-flex section-four">
            <div className="columns">
              <div className="column is-three-quarters">
                <div className="is-spaced">
                  <span className="title is-size-3-desktop is-size-5-mobile">
                    {data.bottomSection.title}
                  </span>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-three-quarters">
                <Category title="Contributors">
                  <a href="https://coil.com">
                    <figure className="image center is-128x128">
                      <Assets.CoilLogo />
                    </figure>
                  </a>
                  <a href="https://www.thitsaworks.com/">
                    <figure className="image center is-128x128">
                      <StaticImage
                        src="../../images/thitsawork.png"
                        alt="Thitsawork"
                      />
                    </figure>
                  </a>
                  <a href="https://modusbox.com">
                    <figure className="image center is-256x256">
                      <StaticImage
                        src="../../images/Modusbox_Logos_Modusbox_Horizontal_Blue.png"
                        alt="ModusBox Inc."
                      />
                    </figure>
                  </a>
                  <a href="https://www.appliedpaymentstech.com/">
                    <figure className="image center is-256x256">
                      <StaticImage
                        src="../../images/AppliedPaymentsLogo.png"
                        alt="Applied Payments"
                      />
                    </figure>
                  </a>
                  {/* <a href="https://google.com">
                    <figure className="image center is-128x128">
                      <StaticImage
                        src="../../images/googlelogo_color_272x92dp.png"
                        alt="Google Inc."
                      />
                    </figure>
                  </a> */}
                </Category>
              </div>
            </div>
          </div>
        </section>
        <div className="container has-text-centered">
          <p className="is-size-3-desktop is-size-5-mobile">{`Start Using ${data.title} Now!`}</p>
          <div className="button-group">
            <a className="button is-primary" href={REPO_ADDRESS}>
              GitHub
            </a>
          </div>
          <div className="bottom-text">
            <a href="https://mojaloop.io/"><span>Payment Manager OSS is a Mojaloop Foundation Project</span></a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
