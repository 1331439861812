import { DOCS_ADDRESS, REPO_ADDRESS } from './links';

const config = {
  title: 'Payment Manager OSS',
  subtitle:
    'Open source software that standardizes onboarding and participating in a Mojaloop compatible real-time payment network.',
  buttons: [
    {
      label: 'Get Started',
      href: DOCS_ADDRESS,
      className: 'button is-primary',
    },
    {
      label: 'GitHub',
      href: REPO_ADDRESS,
      className: 'button is-primary is-outlined',
    },
  ],
  tabPanel: {
    tabs: [
      {
        title: 'For Financial Institutions',
        rowOne: {
          title: 'Get connected quickly. Stay connected securely.',
          text: `Connecting to a real-time payment network requires a high level of sophistication and skill. Payment Manager enables your financial institution to easily connect its core systems to the network and provides ongoing security and continuous monitoring so that you can deliver quality financial services to your customers.`,
        },
        rowTwo: {
          cardItems: [
            {
              title: 'Accelerate banking core connections',
              text: `Standardize the process of mapping your financial institution’s backend system to the real-time payment hub. Templates provide a use case-based API that simplifies the scheme’s complexity and reduces the amount of time required to connect.`,
            },
            {
              title: 'Automate and secure communications',
              text: `Automate much of the certificate creation and configuration process to enable an efficient and effective security system. As a result, you no longer need specialized security personnel during the onboarding phase or when updating security certificates.`,
            },
            {
              title: 'Monitor and track 24/7/365',
              text: `Stay informed with built-in dashboards and reports that enable you to manage your technical operations with minimal staff. Built-in controls identify root causes quickly and prevent potential service interruptions through predictive support and monitoring.`,
            },
          ],
        },
      },
      {
        title: 'For Payment Hub Operators',
        rowOne: {
          title: 'Empower financial institutions to participate',
          text: `The challenge to successfully building and operating an open and interoperable payment system is the high level of sophistication required to onboard and support connected financial institutions (FIs). Payment Manager OSS standardizes the process for FIs to connect and manage transactions on your Mojaloop network.`,
        },
        rowTwo: {
          cardItems: [
            {
              title: 'Minimizes onboarding time and effort',
              text: `Dramatically reduce the amount of time it takes to onboard financial partners to your real-time payment hub. Integrated best practice templates can be customized to work with any backend system.`,
            },
            {
              title: 'Standardizes security best practices',
              text: `Automate the certificate exchange process between you and any connected FI with a built-in integration to Connection Manager. This tool auto-configures TLS and JWS files on behalf of the FI once the information is provided by the real-time payment hub operator.`,
            },
            {
              title: 'Centralizes user management',
              text: `Simplify your user management with a centralized system and pre-built connections to integrate with the existing identity manager of your connected financial institutions.`,
            },
          ],
        },
      },
    ],
  },
  bottomSection: {
    title: 'Who is behind Payment Manager?',
    adopters: [
      {
        text: 'MMF',
        href: 'https://modusbox.com',
      },
      {
        text: 'Mowali',
        href: 'https://modusbox.com',
      },
      {
        text: 'TIPS',
        href: 'https://modusbox.com',
      },
      {
        text: 'Rwanda',
        href: 'https://modusbox.com',
      },
    ],
  },
};

export default config;
